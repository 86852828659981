<template>
	<header
		v-trap-focus.loop="{
			active: dropdownActive,
		}"
		:class="[
			'print:hidden',
			'c-navigation-header',
			'absolute top-md',
			'w-full h-menu z-50 px-layout-margin',
		]"
	>
		<!-- Dropdown Desktop -->
		<TransitionExt
			:duration="500"
			name="navigation-dropdown-desktop"
			@enter="onEnter"
			@after-leave="onAfterLeave"
		>
			<NavigationHeaderDropdownDesktop
				v-if="dropdownActive && activeItem"
				:item="activeItem"
				class="hidden >=1440:block"
				@close="toggleDropdown"
			/>
		</TransitionExt>

		<!-- Dropdown Mobile -->
		<TransitionExt
			:duration="500"
			name="navigation-dropdown-mobile"
			@enter="onEnter"
			@after-leave="onAfterLeave"
		>
			<NavigationHeaderDropdownMobile
				v-if="dropdownActive"
				:items="items"
				:contact="
					secondary && secondary.length > 0 ? secondary[0] : null
				"
				class=">=1440:hidden"
				@close="toggleDropdown"
			/>
		</TransitionExt>

		<!-- Navigation bar -->
		<div
			:class="[
				'c-navigation-header__bar',
				'relative h-full bg-white rounded-full',
			]"
		>
			<!-- Link of logo -->
			<NuxtLinkExt
				:to="frontpageUrl"
				aria-label="Til forsiden af Slagelse Kommune"
				class="c-navigation-header__logo-link"
			/>
			<!-- Logo -->
			<SvgLogoMark
				:class="[
					'c-navigation-header__logo-mark pointer-events-none',
					'absolute left-0 bottom-0 text-extra',
					'>=1440:left-36',
				]"
			/>

			<SvgWordMark
				:class="[
					'c-navigation-header__word-mark pointer-events-none',
					'absolute left-xl top-sm ml-24 text-text',
					'>=1440:left-36 >=1440:top-full >=1440:ml-0 >=1440:mt-xs',
				]"
			/>

			<!-- Navigation points -->
			<nav
				:class="[
					'absolute top-0 left-1/2 h-full',
					'transform -translate-x-1/2',
					'hidden >=1440:flex items-center',
				]"
			>
				<div
					v-for="(item, index) in items"
					:key="`nav-item-${item.id}`"
				>
					<button
						v-if="item.isMegaMenu"
						aria-controls="navigation-dropdown"
						:aria-expanded="activeItem && item.id == activeItem.id"
						:class="[
							'duration-200 ease-smooth-out',
							'text-text hover:text-primary-button',
							'text-left px-sm py-4',
							'group block',

							{
								'border-l border-black border-opacity-10':
									index !== 0,
							},
						]"
						@click="() => toggleDropdown(item)"
					>
						<div
							:class="[
								'text-16 font-semibold uppercase',
								'flex items-center space-x-12',
							]"
						>
							<span
								class="whitespace-nowrap"
								v-text="item.title"
							></span>

							<SvgCaret
								v-if="item.isMegaMenu"
								:class="[
									'duration-200 ease-smooth-out',
									'transform -translate-y-2 rotate-90',
									'w-10',

									{
										'group-hover:translate-y-0': !(
											activeItem &&
											item.id == activeItem.id
										),
										'-rotate-90 -translate-y-2':
											activeItem &&
											item.id == activeItem.id,
									},
								]"
							/>
						</div>
					</button>

					<NuxtLinkExt
						v-else
						:to="item.url"
						:target="item.target"
						:class="[
							'duration-200 ease-smooth-out',
							'text-text hover:text-primary-button',
							'text-left px-sm py-4',
							'group block',

							{
								'border-l border-black border-opacity-10':
									index !== 0,
							},
						]"
					>
						<template #default>
							<div
								:class="[
									'text-16 font-semibold uppercase',
									'flex items-center space-x-12',
								]"
							>
								<span
									class="whitespace-nowrap"
									v-text="item.title"
								></span>

								<SvgCaret
									v-if="item.isMegaMenu"
									:class="[
										'duration-200 ease-smooth-out',
										'transform -translate-y-2 rotate-90',
										'w-10',

										{
											'group-hover:translate-y-0': !(
												activeItem &&
												item.id == activeItem.id
											),
											'-rotate-90 -translate-y-2':
												activeItem &&
												item.id == activeItem.id,
										},
									]"
								/>
							</div>
						</template>
					</NuxtLinkExt>
				</div>
			</nav>

			<!-- Buttons -->
			<div
				:class="[
					'absolute right-0 top-0 h-full',
					'flex items-center space-x-8 pr-8',
					'>=1440:space-x-12 >=1440:pr-16',
				]"
			>
				<NuxtLinkExt
					v-if="supportPage"
					class="hidden >=1440:block"
					:target="supportPage.target"
					:to="supportPage.url"
				>
					<BaseButton class="text-16" tag="div" v-text="'Kontakt'" />
				</NuxtLinkExt>

				<NuxtLinkExt
					v-if="secondary && secondary.length > 0"
					target="_self"
					:to="secondary[0].url"
					:aria-label="secondary[0].name"
					:class="[
						'w-fit h-40 rounded-full bg-primary-button text-white text-button',
						'justify-center items-center px-16 uppercase font-semibold',
						'duration-500 ease-smooth-out hover:bg-primary-button-hover',
						'hidden >=1440:flex',
					]"
				>
					{{ secondary[0].name }}
				</NuxtLinkExt>

				<NuxtLinkExt
					v-if="searchPage"
					:target="searchPage.target"
					:to="searchPage.url"
					aria-label="Gå til søgning"
					:class="[
						'w-40 h-40 rounded-full bg-extra',
						'flex justify-center items-center',
					]"
				>
					<SvgSearch class="w-16 text-white" />
				</NuxtLinkExt>

				<button
					:class="[
						'relative w-40 h-40 rounded-full bg-primary-button',
						'flex justify-center items-center',
						'>=1440:hidden',
					]"
					:aria-label="`${dropdownActive ? 'Luk' : 'Åbn'} menuen`"
					@click="() => toggleDropdown(null, true)"
				>
					<Transition name="navigation-header__burger">
						<SvgBurger
							v-if="!dropdownActive"
							key="burger"
							:class="[
								'absolute top-1/2 left-1/2',
								'transform -translate-x-1/2 -translate-y-1/2',
								'w-16 text-white',
							]"
						/>
						<SvgCross
							v-if="dropdownActive"
							key="cross"
							:class="[
								'absolute top-1/2 left-1/2',
								'transform -translate-x-1/2 -translate-y-1/2',
								'w-12 text-white',
							]"
						/>
					</Transition>
				</button>
			</div>
		</div>
	</header>
</template>

<script>
import trapFocus from '~/citi-baseline/directives/trap-focus.directive.js';
import NavigationHeaderDropdownDesktop from '~/components/main/NavigationHeaderDropdownDesktop';
import NavigationHeaderDropdownMobile from '~/components/main/NavigationHeaderDropdownMobile';
import SvgLogoMark from '~/assets/svgs/logomark.svg?inline';
import SvgWordMark from '~/assets/svgs/wordmark.svg?inline';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import SvgBurger from '~/assets/svgs/burger-icon.svg?inline';
import SvgCross from '~/assets/svgs/cross-icon.svg?inline';
import SvgSearch from '~/assets/svgs/search-icon.svg?inline';

export default {
	name: 'NavigationHeader',

	components: {
		NavigationHeaderDropdownDesktop,
		NavigationHeaderDropdownMobile,
		SvgLogoMark,
		SvgWordMark,
		SvgCaret,
		SvgBurger,
		SvgCross,
		SvgSearch,
	},

	directives: {
		trapFocus,
	},

	data() {
		return {
			frontpageUrl: this.$store.state.site?.url || '/',
			items: this.$store.state.site?.navigation?.main,
			secondary: this.$store.state.site?.navigation?.secondary,
			searchPage: this.$store.state.site?.searchPage,
			supportPage: this.$store.state.site?.supportPage,
			dropdownActive: false,
			activeItem: null,
		};
	},

	watch: {
		$route() {
			this.activeItem = null;
			this.dropdownActive = false;
		},
	},

	methods: {
		toggleDropdown(item, forced) {
			if (!forced && (!item || item?.id === this.activeItem?.id)) {
				this.$emit('drop-down', false);
				this.dropdownActive = false;
				this.activeItem = null;
				return;
			}

			this.dropdownActive = forced ? !this.dropdownActive : true;
			this.activeItem = this.dropdownActive ? item : null;
			this.$emit('drop-down', this.dropdownActive);
		},

		onEnter() {
			const __nuxt = document.body.querySelector('#__nuxt');

			const scrollTopPosition =
				document.body.scrollTop || document.documentElement.scrollTop;
			const scrollLeftPosition =
				document.body.scrollLeft || document.documentElement.scrollLeft;

			__nuxt.style.overflow = 'hidden';
			__nuxt.scrollTop = scrollTopPosition;
			__nuxt.scrollLeft = scrollLeftPosition;
		},

		onAfterLeave() {
			const __nuxt = document.body.querySelector('#__nuxt');

			const scrollTopPosition = __nuxt.scrollTop;
			const scrollLeftPosition = __nuxt.scrollLeft;

			__nuxt.style.overflow = null;

			document.body.scrollTop = scrollTopPosition;
			document.documentElement.scrollTop = scrollTopPosition;

			document.body.scrollLeft = scrollLeftPosition;
			document.documentElement.scrollLeft = scrollLeftPosition;
		},
	},
};
</script>

<style lang="postcss">
.c-navigation-header__bar {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.c-navigation-header__logo-link {
	@apply h-xl;
	position: absolute;
	left: 0;
	bottom: 0;
	width: calc(
		var(--theme-spacing-xl, var(--theme-spacing-xl--sm)) * 2 + 34px
	);
}

.c-navigation-header__logo-mark,
.c-navigation-header__word-mark {
	@apply h-xl;
}

@screen >=1440 {
	.c-navigation-header__logo-link {
		left: 36px;
		bottom: -52px;
		width: 112px;
		height: 148px;
	}

	.c-navigation-header__logo-mark,
	.c-navigation-header__word-mark {
		height: 96px;
	}
}

@screen >=1440 {
	.c-navigation-header > * {
		width: calc(
			100% + var(--theme-spacing-menu, var(--theme-spacing-menu--sm))
		) !important;
		margin-left: calc(
			var(--theme-spacing-menu, var(--theme-spacing-menu--sm)) / 2 * -1
		) !important;
	}
}

.navigation-header__burger-enter-active,
.navigation-header__burger-leave-active {
	@apply duration-500 ease-smooth-out transform;
}

.navigation-header__burger-enter {
	transform: translate(-50%, -50%) rotate(-180deg) !important;

	@apply opacity-0;
}

.navigation-header__burger-leave-to {
	transform: translate(-50%, -50%) rotate(180deg) !important;

	@apply opacity-0;
}
</style>
