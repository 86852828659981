var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"trap-focus",rawName:"v-trap-focus.loop",value:({
		active: _vm.dropdownActive,
	}),expression:"{\n\t\tactive: dropdownActive,\n\t}",modifiers:{"loop":true}}],class:[
		'print:hidden',
		'c-navigation-header',
		'absolute top-md',
		'w-full h-menu z-50 px-layout-margin' ]},[_c('TransitionExt',{attrs:{"duration":500,"name":"navigation-dropdown-desktop"},on:{"enter":_vm.onEnter,"after-leave":_vm.onAfterLeave}},[(_vm.dropdownActive && _vm.activeItem)?_c('NavigationHeaderDropdownDesktop',{staticClass:"hidden >=1440:block",attrs:{"item":_vm.activeItem},on:{"close":_vm.toggleDropdown}}):_vm._e()],1),_vm._v(" "),_c('TransitionExt',{attrs:{"duration":500,"name":"navigation-dropdown-mobile"},on:{"enter":_vm.onEnter,"after-leave":_vm.onAfterLeave}},[(_vm.dropdownActive)?_c('NavigationHeaderDropdownMobile',{staticClass:">=1440:hidden",attrs:{"items":_vm.items,"contact":_vm.secondary && _vm.secondary.length > 0 ? _vm.secondary[0] : null},on:{"close":_vm.toggleDropdown}}):_vm._e()],1),_vm._v(" "),_c('div',{class:[
			'c-navigation-header__bar',
			'relative h-full bg-white rounded-full' ]},[_c('NuxtLinkExt',{staticClass:"c-navigation-header__logo-link",attrs:{"to":_vm.frontpageUrl,"aria-label":"Til forsiden af Slagelse Kommune"}}),_vm._v(" "),_c('SvgLogoMark',{class:[
				'c-navigation-header__logo-mark pointer-events-none',
				'absolute left-0 bottom-0 text-extra',
				'>=1440:left-36' ]}),_vm._v(" "),_c('SvgWordMark',{class:[
				'c-navigation-header__word-mark pointer-events-none',
				'absolute left-xl top-sm ml-24 text-text',
				'>=1440:left-36 >=1440:top-full >=1440:ml-0 >=1440:mt-xs' ]}),_vm._v(" "),_c('nav',{class:[
				'absolute top-0 left-1/2 h-full',
				'transform -translate-x-1/2',
				'hidden >=1440:flex items-center' ]},_vm._l((_vm.items),function(item,index){return _c('div',{key:("nav-item-" + (item.id))},[(item.isMegaMenu)?_c('button',{class:[
						'duration-200 ease-smooth-out',
						'text-text hover:text-primary-button',
						'text-left px-sm py-4',
						'group block',

						{
							'border-l border-black border-opacity-10':
								index !== 0,
						} ],attrs:{"aria-controls":"navigation-dropdown","aria-expanded":_vm.activeItem && item.id == _vm.activeItem.id},on:{"click":function () { return _vm.toggleDropdown(item); }}},[_c('div',{class:[
							'text-16 font-semibold uppercase',
							'flex items-center space-x-12' ]},[_c('span',{staticClass:"whitespace-nowrap",domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),(item.isMegaMenu)?_c('SvgCaret',{class:[
								'duration-200 ease-smooth-out',
								'transform -translate-y-2 rotate-90',
								'w-10',

								{
									'group-hover:translate-y-0': !(
										_vm.activeItem &&
										item.id == _vm.activeItem.id
									),
									'-rotate-90 -translate-y-2':
										_vm.activeItem &&
										item.id == _vm.activeItem.id,
								} ]}):_vm._e()],1)]):_c('NuxtLinkExt',{class:[
						'duration-200 ease-smooth-out',
						'text-text hover:text-primary-button',
						'text-left px-sm py-4',
						'group block',

						{
							'border-l border-black border-opacity-10':
								index !== 0,
						} ],attrs:{"to":item.url,"target":item.target},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{class:[
								'text-16 font-semibold uppercase',
								'flex items-center space-x-12' ]},[_c('span',{staticClass:"whitespace-nowrap",domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),(item.isMegaMenu)?_c('SvgCaret',{class:[
									'duration-200 ease-smooth-out',
									'transform -translate-y-2 rotate-90',
									'w-10',

									{
										'group-hover:translate-y-0': !(
											_vm.activeItem &&
											item.id == _vm.activeItem.id
										),
										'-rotate-90 -translate-y-2':
											_vm.activeItem &&
											item.id == _vm.activeItem.id,
									} ]}):_vm._e()],1)]},proxy:true}],null,true)})],1)}),0),_vm._v(" "),_c('div',{class:[
				'absolute right-0 top-0 h-full',
				'flex items-center space-x-8 pr-8',
				'>=1440:space-x-12 >=1440:pr-16' ]},[(_vm.supportPage)?_c('NuxtLinkExt',{staticClass:"hidden >=1440:block",attrs:{"target":_vm.supportPage.target,"to":_vm.supportPage.url}},[_c('BaseButton',{staticClass:"text-16",attrs:{"tag":"div"},domProps:{"textContent":_vm._s('Kontakt')}})],1):_vm._e(),_vm._v(" "),(_vm.secondary && _vm.secondary.length > 0)?_c('NuxtLinkExt',{class:[
					'w-fit h-40 rounded-full bg-primary-button text-white text-button',
					'justify-center items-center px-16 uppercase font-semibold',
					'duration-500 ease-smooth-out hover:bg-primary-button-hover',
					'hidden >=1440:flex' ],attrs:{"target":"_self","to":_vm.secondary[0].url,"aria-label":_vm.secondary[0].name}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.secondary[0].name)+"\n\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.searchPage)?_c('NuxtLinkExt',{class:[
					'w-40 h-40 rounded-full bg-extra',
					'flex justify-center items-center' ],attrs:{"target":_vm.searchPage.target,"to":_vm.searchPage.url,"aria-label":"Gå til søgning"}},[_c('SvgSearch',{staticClass:"w-16 text-white"})],1):_vm._e(),_vm._v(" "),_c('button',{class:[
					'relative w-40 h-40 rounded-full bg-primary-button',
					'flex justify-center items-center',
					'>=1440:hidden' ],attrs:{"aria-label":((_vm.dropdownActive ? 'Luk' : 'Åbn') + " menuen")},on:{"click":function () { return _vm.toggleDropdown(null, true); }}},[_c('Transition',{attrs:{"name":"navigation-header__burger"}},[(!_vm.dropdownActive)?_c('SvgBurger',{key:"burger",class:[
							'absolute top-1/2 left-1/2',
							'transform -translate-x-1/2 -translate-y-1/2',
							'w-16 text-white' ]}):_vm._e(),_vm._v(" "),(_vm.dropdownActive)?_c('SvgCross',{key:"cross",class:[
							'absolute top-1/2 left-1/2',
							'transform -translate-x-1/2 -translate-y-1/2',
							'w-12 text-white' ]}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }